import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { GetFollowedByMeGQL, Group, Hobby, PaginationInputType } from 'src/app/services/hobbyts.service';
import { ProfileService } from 'src/app/core/profile/profile.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hb-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    MatCheckboxModule,
    MatButtonModule
  ]
})
export class FilterModalComponent implements OnInit {
  @Output() public resetFormEvent = new EventEmitter<void>();

  public filterForm!: FormGroup;
  public isIndoor = false;
  public isOutdoor = false;

  public followers: any[] = [];
  public hobbyGroups: Group[] = [];

  public selectedFilter!: PaginationInputType | null;
  public selectedHobbies: Hobby[] = [];
  public hobbies: Hobby[] = [];

  constructor(
    private dialogRef: MatDialogRef<FilterModalComponent>,
    private formBuilder: FormBuilder,
    private getFollowedByMe: GetFollowedByMeGQL,
    private profileService: ProfileService,
    private destroyRef: DestroyRef
  ) {}

  public ngOnInit(): void {
    this.generateForm(this.selectedFilter);
    this.getFollowedByMe.watch({}, { fetchPolicy: 'no-cache' }).valueChanges.subscribe((data: any) => {
      const result = data.data.getFollowedByMe;
      for (const follower of result) {
        this.followers.push({
          id: follower.id,
          name: `${follower.firstName} ${follower.lastName}`
        });
      }
    });
    this.profileService
      .getHobbies()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        this.hobbyGroups = data.getAllHobbies.hobbyGroups;
        this.hobbies = data.getAllHobbies.hobbies;
        this.selectedHobbies = this.profileService.getSelectedHobbyItems();
        this.setSelectedHobby();
      });
  }

  public submitForm(): void {
    this.dialogRef.close({ ...this.filterForm.value });
  }

  public resetForm(): void {
    this.filterForm.setValue({
      hobby: '',
      followedBy: '',
      startDate: '',
      startTime: '',
      teamSize: null,
      isIndoor: null,
      isOutdoor: null,
      openToJoin: null
    });
    this.resetFormEvent.emit();
  }

  public closeDialog(): void {
    this.dialogRef.close(null);
  }

  private setSelectedHobby(filterSportType?: string | null): void {
    const sportTypeId = filterSportType ?? this.selectedHobbies[0]?.id;

    const selectedHobby = this.hobbies.find(hobby => hobby.id === sportTypeId);
    if (selectedHobby) {
        this.filterForm.get('hobby')?.setValue(selectedHobby);
    } else {
        console.error('Hobby not found');
    }
  }

  private generateForm(filterDetails: PaginationInputType | null): void {
    this.filterForm = this.formBuilder.group({
      hobby: [],
      followedBy: [filterDetails?.followedBy],
      startDate: [filterDetails?.startDate],
      startTime: [filterDetails?.startTime],
      teamSize: [filterDetails?.teamSize],
      isIndoor: [filterDetails?.isIndoor],
      isOutdoor: [filterDetails?.isOutdoor],
      openToJoin: [filterDetails?.openToJoin]
    });
    if (filterDetails?.hobby) {
      this.setSelectedHobby(filterDetails?.hobby);
    }
  }
}
