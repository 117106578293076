<header class="top-bar flex-row justify-center">
  <button class="close-icon cursor-pointer" (click)="closeDialog()" aria-label="Close">
    <mat-icon>close</mat-icon>
  </button>
  <h1 class="page-label" i18n>Filter</h1>
</header>
<section [formGroup]="filterForm" class="flex-col gap-10 filter-form">
  <mat-form-field class="custom-mat-form-field h-40">
    <mat-label class="mat-label" i18n>Choose by followed</mat-label>
    <mat-select panelClass="select-panel" formControlName="followedBy">
      @for (fbName of followers; track fbName.id) {
        <mat-option [value]="fbName?.id">
          {{ fbName?.name }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="custom-mat-form-field h-40">
    <mat-label class="mat-label" i18n>Select Sport Type</mat-label>
    <mat-select panelClass="select-panel" formControlName="hobby">
      <mat-optgroup [label]="'Selected'">
        @for (hobby of selectedHobbies; track hobby.id) {
          <mat-option [value]="hobby">
            {{ hobby.title }}
          </mat-option>
        }
      </mat-optgroup>
      @for (group of hobbyGroups; track group.id) {
        <mat-optgroup [label]="group.title">
          @for (hobby of hobbies; track hobby.id) {
            @if (hobby.groupId === group.id) {
              <mat-option [value]="hobby">
                {{ hobby.title }}
              </mat-option>
            }
          }
        </mat-optgroup>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field class="custom-mat-form-field h-40">
    <mat-label class="mat-label" i18n>Choose a start date</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="startDate" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="custom-mat-form-field h-40 time">
    <mat-label class="mat-label" i18n>Choose a start time</mat-label>
    <input type="text" matInput [ngxMatTimepicker]="timepicker" class="mat-select-value-text"
      formControlName="startTime" />
    <mat-icon class="time-icon">access_time</mat-icon>
    <ngx-mat-timepicker #timepicker></ngx-mat-timepicker>
  </mat-form-field>
  <div class="flex mb-10">
    <mat-checkbox color="primary" labelPosition="before" formControlName="isIndoor" i18n>Indoor</mat-checkbox>
    <mat-checkbox color="primary" labelPosition="before" formControlName="isOutdoor" i18n>Outdoor</mat-checkbox>
  </div>
  <mat-checkbox color="primary" labelPosition="before" formControlName="openToJoin" i18n>Open
    to join</mat-checkbox>
  <div class="team-size date-section flex-row flex-wrap start flex-start gap-10 mt-16">
    <p class="team-size-txt" i18n>Team size</p>
    <mat-form-field appearance="fill" class="team-input">
      <input matInput type="number" class="center-text" formControlName="teamSize" min="2" max="10" />
    </mat-form-field>
    <p class="text-players">10/10</p>
  </div>
</section>
<footer class="submit-buttons flex-col gap-10">
  <button class="fill-button" mat-raised-button (click)="submitForm()" i18n aria-label="Apply filter">Apply</button>
  <button class="outline-button" mat-raised-button (click)="resetForm()" i18n aria-label="Reset filter">Reset</button>
  <button class="outline-button" mat-raised-button (click)="closeDialog()" i18n
    aria-label="Cancel filter">Cancel</button>
</footer>